<template>
  <div class="rtl">
    <v-card>
      <v-card-title>
        <h1 class="panelTitle" style="margin:15px;">
          وضعیت کاربران
        </h1>
      </v-card-title>

      <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(item, index) in items" :key="index">
          <v-card flat>
            <v-card-text>
              <v-card>
                <v-card-title>
                  وضعیت کاربران
                  <v-spacer></v-spacer>
                  <v-text-field v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details class="searchStyle"></v-text-field>
                </v-card-title>
                <v-data-table :headers="headers" :items="ConfirmationStatusList[confirmListParts[index]]" :search="search">
                  <template v-slot:item.operations="{ item }">
                    <!-- <router-link :to="'/documentUserManage/' + item.username">
                      <v-btn class="whiteButton" style="margin-left: 10px" @click="$root.choosedUser = item.username">پرونده</v-btn>
                    </router-link>
                    <router-link to="/documentUserManage">
                      <v-btn class="whiteButton" style="margin-left: 10px" @click="$root.choosedUser = item.username">سوابق مالی</v-btn>
                    </router-link>
                    <router-link to="/securityUser">
                      <v-btn class="whiteButton" style="margin-left: 10px" @click="$root.choosedUser = item.username">امنیت</v-btn>
                    </router-link>
                    <router-link to="/userMessages">
                      <v-btn class="whiteButton" style="margin-left: 10px" @click="$root.choosedUser = item.username">پیام ها</v-btn>
                    </router-link> -->
                    <div style="display:flex;justify-content:space-around">
                      <router-link :to="'/documentUserManage/' + item.username">
                        <!-- <v-btn class="whiteButton" style="margin-left: 5px;min-width: 120px!important;">پرونده</v-btn> -->
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" v-on="on">
                              <v-icon large>
                                mdi-folder-open
                              </v-icon>
                            </v-btn>

                          </template>
                          <span style="color:white">پرونده</span>
                        </v-tooltip>
                      </router-link>
                      <router-link :to="'/turnOverUser/' + item.username">
                        <!-- <v-btn class="whiteButton" style="margin-left: 5px;min-width: 120px!important;" @click="$root.choosedUser = item.username">سوابق مالی</v-btn> -->
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" @click="$root.choosedUser = item.username" v-on="on">
                              <v-icon large>
                                mdi-clipboard-text-clock-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span style="color:white">سوابق مالی</span>
                        </v-tooltip>
                      </router-link>
                      <router-link to="/securityUser">
                        <!-- <v-btn class="whiteButton" style="margin-left: 5px;min-width: 120px!important;" @click="$root.choosedUser = item.username">امنیت</v-btn> -->
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" @click="$root.choosedUser = item.username" v-on="on">
                              <v-icon large>
                                mdi-shield-account-outline
                              </v-icon>
                            </v-btn>

                          </template>
                          <span style="color:white">امنیت</span>
                        </v-tooltip>
                      </router-link>
                      <router-link to="/userMessages">
                        <!-- <v-btn class="whiteButton" style="margin-left: 5px;min-width: 120px!important;" @click="$root.choosedUser = item.username">پیام ها</v-btn> -->
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="whiteButton btn_index iconBtn" v-bind="attrs" @click="$root.choosedUser = item.username" v-on="on">
                              <v-icon large>
                                mdi-message-settings-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span style="color:white">پیام ها</span>
                        </v-tooltip>
                      </router-link>
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import axiosApi from "@/axios";
import getPersianDateTime from "@/plugins/getPersianDate";
// import Vue from "vue";

export default {
  name: "ConfirmationStatusList",
  data() {
    return {
      confirmListParts: ['addressInfos', 'bankAccounts', 'personalInfos', 'userImages'],
      ConfirmationStatusList: '',
      tab: null,
      headers: [
        { text: 'نام ', value: 'firstName' },
        { text: 'فامیل ', value: 'lastName' },
        { text: 'تلفن همراه', value: 'username' },
        { text: 'عملیات', value: 'operations' },
      ],
      items: [
        'اطلاعات آدرس', 'اطلاعات بانکی', 'اطلاعات شخصی', 'اطلاعات عکس ها',
      ],
    };
  },
  mounted() {
    this.getConfirmationStatusList();
  }
  ,
  methods: {
    getPersianDate(date) {
      return getPersianDateTime(date);
    },
    getConfirmationStatusList() {
      axiosApi().post('api/Manage/v1/Report/GetConfirmationStatusList', {
        confirmationType: this.$root.confirmationType
      }).then(({ data }) => {
        this.ConfirmationStatusList = data.data;
      });
    }

  }
};
</script>

<style scoped>
/* * {
  font-family: YekanBakh !important;
  font-size: 14px;
} */
</style>
